/* Helpers */
import ready from './helpers/ready';
import { listenBodyScroll } from './helpers/freezeBodyPosition.js';
import cookieConsentConfig from './partials/cookie-consent-config';

/* Vendors */
import './vendors/vanilla-cookie-consent.js';


ready(() => {
    listenBodyScroll();
    cookieConsentConfig();
  });