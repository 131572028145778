export default function cookieConsentConfig() {
    // obtain vendor's plugin
    let cc = initCookieConsent();

    // run plugin with custom configuration
    cc.run({
        current_lang: 'cs',
        autoclear_cookies: true, // default: false
        //theme_css: '<path-to-cookieconsent.css>',  // use my scss regular files
        page_scripts: true, // default: false

        mode: 'opt-in', // default: 'opt-in'; value: 'opt-in' or 'opt-out'
        // delay: 0,                               // default: 0
        auto_language: document, // default: null; could also be 'browser' or 'document'
        // autorun: true,                          // default: true
        // force_consent: false,                   // default: false
        hide_from_bots: true, // default: false
        remove_cookie_tables: true,             // default: false
        // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
        // cookie_expiration: 182,                 // default: 182 (days)
        // cookie_necessary_only_expiration: 182   // default: disabled
        // cookie_domain: location.hostname,       // default: current domain
        // cookie_path: '/',                       // default: root
        // cookie_same_site: 'Lax',                // default: 'Lax'
        // use_rfc_cookie: false,                  // default: false
        // revision: 0,                            // default: 0

        onFirstAction: function (user_preferences, cookie) {
            if (!cc.allowedCategory('analytics')) {

                // Disable gtag ...
                window.dataLayer = window.dataLayer || [];

                function gtag() { dataLayer.push(arguments); }

                gtag('consent', 'default', {
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied'
                });

                window.location.reload();
            }
        },

        onAccept: function (cookie) {
        },

        onChange: function (cookie, changed_preferences) {
            if (!cc.allowedCategory('analytics')) {

                // Disable gtag ...
                window.dataLayer = window.dataLayer || [];

                function gtag() { dataLayer.push(arguments); }

                gtag('consent', 'default', {
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied'
                });

                window.location.reload();
            }
        },

        languages: {
            cs: {
                consent_modal: {
                    title: '🍪 Pro lepší požitek na webu - souhlas s&nbsp;cookies',
                    description:
                        'Pro lepší požitek na webu a komunikaci s Vámi, potřebujeme váš souhlas se zpracováním souborů cookies. Více informaci <a href="https://www.mcsystems.cz/privacy" target="_blank" class="cc-link">naleznete zde</a>.',
                    primary_btn: {
                        text: 'Souhlasím',
                        role: 'accept_all', // 'accept_selected' or 'accept_all'
                    },
                    secondary_btn: {
                        text: 'Nastavení',
                        role: 'settings', // 'settings' or 'accept_necessary'
                    },
                },
                settings_modal: {
                    title: 'Nastavení cookies',
                    save_settings_btn: 'Potvrdit volby',
                    accept_all_btn: 'Povolit vše',
                    reject_all_btn: 'Zamítnout vše',
                    close_btn_label: 'Zavřit',
                    cookie_table_headers: [
                        {col1: 'Cookie'},
                    ],
                    blocks: [
                        {
                            //title: 'Nastavení preferencí cookies',
                            description:
                                'Soubory cookie používáme k&nbsp;zajištění základních funkcí webu a&nbsp;ke zlepšení vašeho online zážitku. Pro každou kategorii si můžete vybrat, zda se chcete přihlásit/odhlásit a&nbsp;to kdykoli budete chtít. Podmínky zpracování <a href="https://www.mcsystems.cz/privacy" target="_blank" class="cc-link">naleznete zde</a>.',
                        },
                        {
                            title: 'Technické a&nbsp;nutné cookies',
                            description:
                                'Tyto cookies soubory jsou nezbytné pro správné fungování webových stránek. Bez těchto cookies by web nefungoval správně.',
                            toggle: {
                                value: 'necessary',
                                enabled: true,
                                readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                            },
                        },
                        {
                            title: 'Analytické cookies',
                            description:
                                'Tyto soubory cookie shromažďují informace o&nbsp;tom, jak web používáte, které stránky jste navštívili a&nbsp;na které odkazy jste klikli. Všechna data jsou anonymizována a&nbsp;nelze je použít k&nbsp;vaší identifikaci.',
                            toggle: {
                                value: 'analytics',
                                enabled: false,
                                readonly: false,
                            },
                            cookie_table: [             
                                {
                                    col1: '^_ga', // match all cookies starting with "_ga"
                                    is_regex: true
                                },
                                {
                                    col1: '_gid',
                                }
                            ]
                        },
                        {
                            title: 'Marketingové cookies',
                            description: 'Reklamní cookies používáme my nebo naši partneři, abychom vám mohli připomenout nabídky, které jste si prohlíželi na našich stránkách i jinde na internetu. Souhlas s těmito cookies lze kdykoliv odvolat.',
                            toggle: {
                                value: 'marketing',
                                enabled: false,
                                readonly: false
                            }
                        }, {
                            title: 'Více informací',
                            description:
                                'V souvislosti s&nbsp;ochranou osobních údajů máte řadu práv. Pokud budete chtít některého z&nbsp;těchto práv využít, prosím, kontaktujte nás prostřednictvím e-mailu: <a href="mailto:info@mcsystems.cz" class="cc-link">info@mcsystems.cz</a>',
                            is_highlighted: true,
                        },
                    ],
                },
            },
        },
        gui_options: {
            consent_modal: {
                layout: 'bar', // box/cloud/bar
                position: 'bottom', // bottom/middle/top + left/right/center
                transition: 'slide', // zoom/slide
                swap_buttons: true, // enable to invert buttons
            },
            settings_modal: {
                layout: 'box', // box/bar
                // position: 'left',           // left/right
                transition: 'slide', // zoom/slide
            },
        },
    });
}
