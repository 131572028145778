export const freezeBodyPosition = () => {
    let scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    document.body.style.position = 'fixed';
    document.body.style.top = '-' + scrollY;
};

export const resetBodyPosition = () => {
    let scrollY = document.body.style.top;
    document.body.style.position = null;
    document.body.top = null;
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
}; // Trigger modal


export const listenBodyScroll = () => {
    window.addEventListener('scroll', () => {
        document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
    });
}